import { ImageDetails } from '../Components/Gallery';
// https://www.montvilleartgallery.com.au/artist/sophie-malkin/

const ImagesPath = `images/galleryimages/`;

export const FromMe = {
  availableAt: 'Contact Me',
  availableAtUrl: '/contact',
};

export const MontvilleArtGallery = {
  availableAt: 'Montville Art Gallery',
  availableAtUrl:
    'https://www.montvilleartgallery.com.au/artist/sophie-malkin/',
};

export const FromHillsdon = FromMe;

export const Sold = {
  availableAt: 'sold',
  availableAtUrl: '',
};

const Comission = {
  availableAt: 'commision',
  availableAtUrl: '',
};

const Unavailable = {
  availableAt: 'Unavailable',
  availableAtUrl: '',
};

export const TowerArtShow = {
  availableAt: 'The Tower Art Show 2024',
  availableAtUrl:
    'https://www.marash.qld.edu.au/news-events/events/tower-art-show/',
};

const Types = {
  OilOnCanvas: 'Oil on canvas',
  OilOnCanvasFramed: 'Oil on canvas, framed',
  OilOnBoard: 'Oil on board',
  OilOnBoardFramed: 'Oil on board, framed',
  OilOnCanvasPaper: 'Oil on canvas Paper',
  OilOnCanvasPaperFramed: 'Oil on canvas Paper, framed',
};

const data: ImageDetails[] = [
  {
    img: `${ImagesPath}118.jpg`,
    title: 'First Light, New Zealand',
    height: 61,
    width: 76.5,
    type: Types.OilOnCanvas,
    price: 1300,
    ...MontvilleArtGallery,
  },
  {
    img: `${ImagesPath}117.jpg`,
    title: 'Golden Hour',
    height: 61,
    width: 61,
    type: Types.OilOnCanvas,
    price: 950,
    ...MontvilleArtGallery,
  },
  {
    img: `${ImagesPath}116.jpg`,
    title: 'Fire Show, Byron Bay',
    height: 45.5,
    width: 91,
    type: Types.OilOnCanvas,
    price: 1300,
    ...Sold,
  },
  {
    img: `${ImagesPath}115.jpg`,
    title: 'Gently Rolling In',
    height: 20,
    width: 30,
    frameHeight: 23,
    frameWidth: 33,
    type: Types.OilOnBoard,
    price: 500,
    ...MontvilleArtGallery,
  },
  {
    img: `${ImagesPath}114.jpg`,
    title: 'Flight over Enoggera Reservoir',
    height: 45.5,
    width: 91,
    frameHeight: 48,
    frameWidth: 94,
    type: Types.OilOnCanvas,
    ...Sold,
  },
  {
    img: `${ImagesPath}113.jpg`,
    title: 'Mount Beerwah',
    height: 25,
    width: 30,
    type: Types.OilOnBoard,
    ...Sold,
  },
  {
    img: `${ImagesPath}112.jpg`,
    title: 'Rocky Shores',
    height: 45.5,
    width: 91,
    frameHeight: 48,
    frameWidth: 94,
    type: Types.OilOnCanvas,
    price: 1400,
    ...MontvilleArtGallery,
  },
  {
    img: `${ImagesPath}111.jpg`,
    title: 'White Riverbed, New Zealand',
    height: 45.8,
    width: 45.8,
    type: Types.OilOnCanvas,
    price: 790,
    ...Sold,
  },
  {
    img: `${ImagesPath}110.jpg`,
    title: 'Glacial Waters, New Zealand',
    height: 45.5,
    width: 45.5,
    type: Types.OilOnCanvas,
    ...MontvilleArtGallery,
  },
  {
    img: `${ImagesPath}109.jpg`,
    title: 'Cliff Face, Springbrook',
    height: 45.5,
    width: 91,
    frameHeight: 48,
    frameWidth: 94,
    type: Types.OilOnCanvas,
    price: 1400,
    ...Sold,
  },
  {
    img: `${ImagesPath}108.jpg`,
    title: 'Point Cartwright',
    height: 61,
    width: 76.5,
    frameHeight: 64,
    frameWidth: 79,
    type: Types.OilOnCanvas,
    ...Sold,
  },
  {
    img: `${ImagesPath}107.jpg`,
    title: 'Rocky Shallows',
    height: 45.5,
    width: 91,
    frameHeight: 48,
    frameWidth: 94,
    type: Types.OilOnCanvas,
    price: 1400,
    ...MontvilleArtGallery,
  },
  {
    img: `${ImagesPath}106.jpg`,
    title: "Jolly's Lookout",
    height: 78,
    width: 101.5,
    type: Types.OilOnCanvas,
    ...Sold,
  },
  {
    img: `${ImagesPath}105.jpg`,
    title: "Ocean's Edge",
    height: 30.5,
    width: 61,
    type: Types.OilOnCanvas,
    price: 650,
    ...MontvilleArtGallery,
  },
  {
    img: `${ImagesPath}104.jpg`,
    title: 'Reflection',
    height: 30,
    width: 30,
    type: Types.OilOnBoard,
    ...Sold,
  },
  {
    img: `${ImagesPath}103.jpg`,
    title: 'Point Cartwright Rocks (board)',
    height: 30,
    width: 40,
    type: Types.OilOnBoard,
    ...Sold,
  },
  {
    img: `${ImagesPath}102.jpg`,
    title: 'Light of the Forest (Lake Matheson track, New Zealand) ',
    height: 61,
    width: 61,
    type: Types.OilOnCanvas,
    price: 920,
    ...Sold,
  },
  {
    img: `${ImagesPath}101.jpg`,
    title: 'Lauterbrunnin Valley',
    height: 40,
    width: 51,
    type: Types.OilOnCanvas,
    ...Sold,
  },
  {
    img: `${ImagesPath}100.jpg`,
    title: 'Still Waters (Milford Sound, New Zealand)',
    height: 61,
    width: 61,
    type: Types.OilOnCanvas,
    price: 950,
    ...Sold,
  },
  {
    img: `${ImagesPath}99.jpg`,
    title: 'Early Morning Dew (Lake Matheson Walk, New Zealand)',
    height: 30.5,
    width: 61,
    price: 650,
    type: Types.OilOnCanvas,
    ...MontvilleArtGallery,
  },
  {
    img: `${ImagesPath}98.jpg`,
    title: 'Cactus over the Italian Coast',
    height: 61,
    width: 61,
    type: Types.OilOnCanvas,
    ...Sold,
  },
  {
    img: `${ImagesPath}97.jpg`,
    title: 'Lily',
    height: 10,
    width: 10,
    type: Types.OilOnCanvas,
    ...Unavailable,
  },
  {
    img: `${ImagesPath}96.jpg`,
    title: 'Noosa Heads',
    height: 30,
    width: 30,
    frameHeight: 33.5,
    frameWidth: 33.5,
    type: Types.OilOnBoardFramed,
    price: 500,
    ...Sold,
  },
  {
    img: `${ImagesPath}95.jpg`,
    title: 'Catching the Light, Enoggera Reservoir',
    height: 45.8,
    width: 91.5,
    frameHeight: 48,
    frameWidth: 94,
    type: Types.OilOnCanvas,
    price: 1100,
    ...Sold,
  },
  {
    img: `${ImagesPath}94.jpg`,
    title: "Bird's View over Enoggera Reservoir",
    height: 45.8,
    width: 91.5,
    frameHeight: 48,
    frameWidth: 94,
    type: Types.OilOnCanvas,
    price: 1100,
    ...Sold,
  },
  {
    img: `${ImagesPath}93.jpg`,
    title: 'Garden Retreat',
    height: 61,
    width: 76.5,
    type: Types.OilOnCanvas,
    ...Sold,
  },
  {
    img: `${ImagesPath}91.jpg`,
    title: 'Storm Approaching, Shelly Beach',
    height: 30,
    width: 30,
    frameHeight: 33.5,
    frameWidth: 33.5,
    type: Types.OilOnBoardFramed,
    price: 500,
    ...Sold,
  },
  {
    img: `${ImagesPath}89.jpg`,
    title: 'Bathers Bay, Fremantle',
    height: 50.9,
    width: 40.7,
    type: Types.OilOnCanvas,
    price: 680,
    ...MontvilleArtGallery,
  },
  {
    img: `${ImagesPath}88.jpg`,
    title: 'Last of the Sun, Lake Baroon',
    height: 61,
    width: 76.5,
    type: Types.OilOnCanvas,
    price: 1000,
    ...Sold,
  },
  {
    img: `${ImagesPath}86.jpg`,
    title: 'Early Morning Mist, Lake Baroon',
    height: 30.5,
    width: 61,
    type: Types.OilOnCanvas,
    price: 650,
    ...MontvilleArtGallery,
  },
  {
    img: `${ImagesPath}85.jpg`,
    title: 'Lake Baroon at Sunset',
    height: 40.7,
    width: 50.9,
    type: Types.OilOnCanvas,
    price: 680,
    ...Sold,
  },
  {
    img: `${ImagesPath}82.jpg`,
    title: 'Cloud Above (Brisbane River)',
    height: 45.8,
    width: 45.8,
    type: Types.OilOnCanvas,
    price: 690,
    ...Sold,
  },
  {
    img: `${ImagesPath}81.jpg`,
    title: 'Hidden Courtyard, Monterosso al Mare',
    height: 30.5,
    width: 61,
    frameHeight: 33,
    frameWidth: 64,
    type: Types.OilOnCanvas,
    price: 730,
    ...Sold,
  },
  {
    img: `${ImagesPath}80.jpg`,
    title: 'Looking Towards Point Cartwright on the Sunshine Coast',
    height: 30,
    width: 41,
    frameHeight: 33.5,
    frameWidth: 44,
    type: Types.OilOnBoardFramed,
    price: 590,
    ...MontvilleArtGallery,
  },
  {
    img: `${ImagesPath}79.jpg`,
    title: 'Rocky Creek, Baxter Falls',
    height: 46,
    width: 61,
    frameHeight: 49,
    frameWidth: 64,
    type: Types.OilOnCanvas,
    price: 880,
    ...Sold,
  },
  {
    img: `${ImagesPath}78.jpg`,
    title: 'Skies over Brisbane',
    height: 40.7,
    width: 50.9,
    frameHeight: 44,
    frameWidth: 54,
    type: Types.OilOnCanvas,
    price: 760,
    ...Sold,
  },
  {
    img: `${ImagesPath}77.jpg`,
    title: 'Shelly Beach',
    height: 13,
    width: 34,
    type: Types.OilOnBoard,
    ...Sold,
  },
  {
    img: `${ImagesPath}76.jpg`,
    title: 'East Ithaca Creek, Mount Coot-Tha',
    height: 24,
    width: 30,
    frameHeight: 27.5,
    frameWidth: 33.5,
    type: Types.OilOnBoard,
    price: 450,
    ...Sold,
  },
  {
    img: `${ImagesPath}74.jpg`,
    title: 'Hidden Waters, Maleny',
    height: 30,
    width: 30,
    frameHeight: 43,
    frameWidth: 43,
    type: Types.OilOnBoard,
    price: 590,
    ...MontvilleArtGallery,
  },
  {
    img: `${ImagesPath}73.jpg`,
    title: 'Dusk, Marcus Beach',
    height: 15,
    width: 30,
    frameHeight: 25,
    frameWidth: 43,
    type: Types.OilOnBoard,
    price: 390,
    ...Sold,
  },
  {
    img: `${ImagesPath}72.jpg`,
    title: 'Late Afternoon, Marcus Beach',
    height: 15,
    width: 30,
    frameHeight: 25,
    frameWidth: 43,
    type: Types.OilOnBoard,
    price: 390,
    ...Sold,
  },
  {
    img: `${ImagesPath}71.jpg`,
    title: 'Misty Forest (Springbrook National Park)',
    height: 50.9,
    width: 40.7,
    frameHeight: 54,
    frameWidth: 44,
    type: Types.OilOnCanvas,
    price: 760,
    ...Sold,
  },
  {
    img: `${ImagesPath}70.jpg`,
    title: 'Mount Coolum in the Distance',
    height: 30.5,
    width: 61,
    frameHeight: 33,
    frameWidth: 64,
    type: Types.OilOnCanvas,
    price: 730,
    ...Sold,
  },
  {
    img: `${ImagesPath}69.jpg`,
    title: 'End of the Day',
    height: 30,
    width: 24,
    frameHeight: 33.5,
    frameWidth: 27,
    type: Types.OilOnBoard,
    price: 450,
    ...Sold,
  },
  {
    img: `${ImagesPath}68.jpg`,
    title: 'Cape Byron lighthouse',
    height: 25,
    width: 30,
    frameHeight: 39,
    frameWidth: 44,
    type: Types.OilOnBoard,
    price: 590,
    ...Sold,
  },
  {
    img: `${ImagesPath}67.jpg`,
    title: 'Pasture Delight',
    height: 24.5,
    width: 30,
    frameHeight: 38,
    frameWidth: 43,
    type: Types.OilOnBoard,
    price: 530,
    ...Sold,
  },
  {
    img: `${ImagesPath}66.jpg`,
    title: 'Hillside Climb',
    height: 24.5,
    width: 30,
    frameHeight: 32,
    frameWidth: 37,
    type: Types.OilOnBoard,
    price: 520,
    ...MontvilleArtGallery,
  },
  {
    img: `${ImagesPath}65.jpg`,
    title: 'Ashore at Baia del Silenzio',
    height: 30.5,
    width: 30.5,
    type: Types.OilOnCanvas,
    price: 450,
    ...Sold,
  },
  {
    img: `${ImagesPath}64.jpg`,
    title: 'Byron Bay Cliffside',
    height: 40.7,
    width: 50.9,
    frameHeight: 44,
    frameWidth: 54,
    type: Types.OilOnCanvas,
    price: 810,
    ...Sold,
  },
  {
    img: `${ImagesPath}63.jpg`,
    title: 'Boulder on the Golden Hillside',
    height: 25,
    width: 25,
    frameHeight: 32,
    frameWidth: 32,
    type: Types.OilOnBoard,
    price: 490,
    ...MontvilleArtGallery,
  },
  {
    img: `${ImagesPath}62.jpg`,
    title: 'On the Grand Canal',
    height: 61,
    width: 76.5,
    type: Types.OilOnCanvas,
    ...Sold,
  },
  {
    img: `${ImagesPath}61.jpg`,
    title: 'West from Mt Tamborine',
    height: 61,
    width: 61,
    type: Types.OilOnCanvas,
    ...Sold,
  },
  {
    img: `${ImagesPath}60.jpg`,
    title: "Tom and Thea's Wedding",
    height: 40,
    width: 65,
    type: Types.OilOnCanvas,
    ...Unavailable,
  },
  {
    img: `${ImagesPath}59.jpg`,
    title: 'Dining Out in Sestri Levante',
    height: 30,
    width: 30,
    type: Types.OilOnBoard,
    ...Sold,
  },
  {
    img: `${ImagesPath}58.jpg`,
    title: 'Resting (Obi Obi Creek)',
    height: 30,
    width: 36.5,
    type: Types.OilOnBoard,
    ...Sold,
  },
  {
    img: `${ImagesPath}57.jpg`,
    title: 'Grand Canal and Santa Maria della Salute',
    width: 61,
    height: 46,
    type: Types.OilOnCanvas,
    price: 790,
    ...Sold,
  },
  {
    img: `${ImagesPath}56.jpg`,
    title: 'Cerulean Blue (Caloundra)',
    width: 61,
    height: 25.5,
    type: Types.OilOnBoard,
    ...Sold,
  },
  {
    img: `${ImagesPath}55.jpg`,
    title: 'Enchanted forest (Mapleton National Park)',
    width: 40.7,
    height: 50.9,
    type: Types.OilOnCanvas,
    price: 690,
    ...FromHillsdon,
  },
  {
    img: `${ImagesPath}54.jpg`,
    title: 'Ripening',
    width: 45.8,
    height: 45.8,
    type: Types.OilOnCanvas,
    price: 690,
    ...Sold,
  },
  {
    img: `${ImagesPath}53.jpg`,
    title: 'Booloumba Creek (Artists Cascades)',
    width: 25,
    height: 30,
    frameHeight: 28,
    frameWidth: 33,
    type: Types.OilOnBoard,
    price: 460,
    ...MontvilleArtGallery,
  },
  {
    img: `${ImagesPath}52.jpg`,
    title: 'Artists Cascade',
    width: 30,
    height: 30,
    type: Types.OilOnBoard,
    ...Sold,
  },
  {
    img: `${ImagesPath}51.jpg`,
    title: 'Rainy Summer, Point Cartwright',
    width: 30,
    height: 24,
    type: Types.OilOnBoard,
    ...Sold,
  },
  {
    img: `${ImagesPath}50.jpg`,
    title: 'Trattoria, Sestri Levante',
    width: 31,
    height: 23,
    type: Types.OilOnBoardFramed,
    ...Sold,
  },
  {
    img: `${ImagesPath}49.jpg`,
    title: 'Top of Europe',
    width: 40.7,
    height: 50.9,
    type: Types.OilOnCanvas,
    ...Sold,
  },
  {
    img: `${ImagesPath}48.jpg`,
    title: 'Passing Shadow',
    width: 30,
    height: 24,
    type: Types.OilOnBoard,
    ...Sold,
  },
  {
    img: `${ImagesPath}46.jpg`,
    title: 'Reading Corner',
    width: 30,
    height: 24,
    type: Types.OilOnBoard,
    ...Sold,
  },
  {
    img: `${ImagesPath}45.jpg`,
    title: 'Wallaby',
    width: 30,
    height: 30,
    frameHeight: 34,
    frameWidth: 34,
    type: Types.OilOnBoard,
    price: 490,
    ...MontvilleArtGallery,
  },
  {
    img: `${ImagesPath}44.jpg`,
    title: 'Kangaroo and Joey',
    width: 45.8,
    height: 45.8,
    type: Types.OilOnCanvas,
    ...Sold,
  },
  {
    img: `${ImagesPath}43.jpg`,
    title: 'Grand Canal, Venice',
    width: 50.9,
    height: 40.7,
    type: Types.OilOnCanvas,
    ...Sold,
  },
  {
    img: `${ImagesPath}42.jpg`,
    title: 'Lauterbrunnen, Switzerland',
    width: 45.8,
    height: 45.8,
    type: Types.OilOnCanvas,
    ...Sold,
  },
  {
    img: `${ImagesPath}40.jpg`,
    title: 'Franciscan Monastery, Dubrovnik',
    width: 30,
    height: 30,
    frameHeight: 34,
    frameWidth: 34,
    type: Types.OilOnBoardFramed,
    price: 500,
    ...Sold,
  },
  {
    img: `${ImagesPath}39.jpg`,
    title: 'Obi Obi Creek',
    width: 45.8,
    height: 45.8,
    type: Types.OilOnCanvas,
    price: 690,
    ...MontvilleArtGallery,
  },
  {
    img: `${ImagesPath}37.jpg`,
    title: 'Incandescence',
    width: 30,
    height: 30,
    type: Types.OilOnBoard,
    ...Sold,
  },
  {
    img: `${ImagesPath}35.jpg`,
    title: 'On the Water',
    width: 34,
    height: 18,
    type: Types.OilOnBoardFramed,
    ...Sold,
  },
  {
    img: `${ImagesPath}32.jpg`,
    title: 'River City at Sunset',
    width: 76.5,
    height: 61,
    type: Types.OilOnCanvas,
    ...Sold,
  },
  {
    img: `${ImagesPath}31.jpg`,
    title: 'Italian Coast',
    width: 30,
    height: 30,
    type: Types.OilOnBoardFramed,
    ...Sold,
  },
  {
    img: `${ImagesPath}30.jpg`,
    title: 'Regular Customer',
    width: 61,
    height: 46,
    type: Types.OilOnCanvas,
    ...Sold,
  },
  {
    img: `${ImagesPath}28.jpg`,
    title: 'End Of The Road',
    width: 30.5,
    height: 30.5,
    type: Types.OilOnCanvas,
    price: 450,
    ...FromMe,
  },
  {
    img: `${ImagesPath}27.jpg`,
    title: 'Late Afternoon in the Forest (Mapleton National Park)',
    width: 76.5,
    height: 61,
    type: Types.OilOnCanvas,
    ...Sold,
  },
  {
    img: `${ImagesPath}24.jpg`,
    title: 'Basking in the Sun',
    width: 30,
    height: 24,
    type: Types.OilOnBoardFramed,
    ...Sold,
  },
  {
    img: `${ImagesPath}23.jpg`,
    title: 'Dancing Reflections (Springbrook National Park)',
    width: 51.5,
    height: 30,
    type: Types.OilOnBoardFramed,
    ...Sold,
  },
  {
    img: `${ImagesPath}22.jpg`,
    title: 'Cactus in the Italian Countryside',
    width: 76.5,
    height: 61,
    type: Types.OilOnCanvas,
    ...Sold,
  },
  {
    img: `${ImagesPath}20.jpg`,
    title: 'Warm Afternoon in Monterosso al Mare',
    width: 30,
    height: 30,
    type: Types.OilOnBoard,
    ...Sold,
  },
  {
    img: `${ImagesPath}19.jpg`,
    title: 'Cafe in Vernazza, Italy',
    width: 30,
    height: 24,
    type: Types.OilOnBoard,
    ...Sold,
  },
  {
    img: `${ImagesPath}15.jpg`,
    title: 'Orange Tree in Italy',
    width: 30.5,
    height: 23,
    type: Types.OilOnBoard,
    ...Sold,
  },
  {
    img: `${ImagesPath}1.jpg`,
    title: 'Sunlit Forest Trail: Sunshine Coast Hinterland Great Walk',
    width: 30.3,
    height: 25,
    type: Types.OilOnBoard,
    ...Sold,
  },
  {
    img: `${ImagesPath}4.jpg`,
    title: 'Cave Creek, Springbrook National Park',
    width: 30,
    height: 30,
    frameHeight: 33,
    frameWidth: 33,
    type: 'Oil on board, framed',
    price: 500,
    ...MontvilleArtGallery,
  },
  {
    img: `${ImagesPath}7.jpg`,
    title: 'Lake Moogerah, Qld',
    width: 30,
    height: 30,
    type: Types.OilOnBoardFramed,
    ...Sold,
  },
  {
    img: `${ImagesPath}16.jpg`,
    title: 'Bunya Mountains Trail',
    width: 61,
    height: 61,
    type: Types.OilOnCanvas,
    ...Sold,
  },
  {
    img: `${ImagesPath}2.jpg`,
    type: 'Oil on board',
    width: 30,
    height: 24,
    title: 'Crashing Wave',
    ...Sold,
  },
  {
    img: `${ImagesPath}8.jpg`,
    title: 'Incoming Wave at Broadbeach',
    width: 61,
    height: 45.5,
    type: Types.OilOnCanvas,
    ...Sold,
  },
  {
    img: `${ImagesPath}5.jpg`,
    title: 'Anniversary Portrait',
    width: 61,
    height: 46,
    type: Types.OilOnCanvas,
    ...Comission,
  },
  {
    img: `${ImagesPath}3.jpg`,
    title: "It's Been A Long Day",
    width: 20,
    height: 20,
    type: Types.OilOnCanvasPaper,
    ...Sold,
  },
  {
    img: `${ImagesPath}6.jpg`,
    title: 'Pet Portrait Demonstration',
    width: 30,
    height: 30,
    type: Types.OilOnBoard,
    ...Unavailable,
  },
  {
    img: `${ImagesPath}14.jpg`,
    title: 'Working Late',
    width: 30,
    height: 30,
    type: Types.OilOnBoard,
    ...Unavailable,
  },
];

const unavailableStatuses = [Sold, Unavailable, Comission].map(
  (thing) => thing.availableAt,
);

data.sort((a, b) => {
  const aIsUnavailable = a.availableAt
    ? unavailableStatuses.includes(a.availableAt)
    : false;
  const bIsUnavailable = b.availableAt
    ? unavailableStatuses.includes(b.availableAt)
    : false;

  if (aIsUnavailable && !bIsUnavailable) {
    return 1;
  }
  if (!aIsUnavailable && bIsUnavailable) {
    return -1;
  }
  return 0;
});

export default data;
