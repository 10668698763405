import React from 'react';
import { useAnalytics } from 'use-analytics';
import pages from './pages';
import { Card, CardMedia, Divider, Typography } from '@mui/material';
import ExternalLink from '../Components/ExternalLink';
import InternalLink from '../Components/InternalLink';
import Gallery from '../Components/Gallery';
import galleryImages, { MontvilleArtGallery } from '../data/galleryImages';

const ArtGalleriesPage: React.FC<unknown> = () => {
  const { page } = useAnalytics();

  React.useEffect(() => {
    // eslint-disable-next-line no-console
    document.title = 'Sophie Malkin Artist: Paintings';
    page({ title: 'Gallery', path: pages.ArtGalleries.path });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ marginTop: 60 }}>
      <Card
        sx={{ textAlign: 'center', paddingBottom: 10 }}
        raised={false}
        elevation={0}
      >
        <ExternalLink to="https://www.montvilleartgallery.com.au/artist/sophie-malkin/">
          <CardMedia
            component="img"
            sx={{
              padding: 1,
              display: 'inline',
              maxWidth: 500,
            }}
            image="https://www.montvilleartgallery.com.au/wp-content/uploads/2017/07/MontvilleArtGalleryLogo.jpg"
            alt="Montville Art Gallery Logo"
          />
        </ExternalLink>
        <div>
          <Typography
            variant="body1"
            gutterBottom
            component="div"
            sx={{
              padding: 2,
            }}
          >
            Visit my works in person at{' '}
            <ExternalLink to="https://g.page/montvilleartgallery?share">
              138 Main Street, Montville, Queensland
            </ExternalLink>
            , or see the Montville Art Gallery website
            <ExternalLink to="https://www.montvilleartgallery.com.au/artist/sophie-malkin/">
              {' '}
              here
            </ExternalLink>
            .
          </Typography>
        </div>
        <iframe
          title="Google Maps Location: Montville Art Gallery"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3564.744994103573!2d152.8899153150402!3d-26.688636983224505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b9381a371d48747%3A0x2e8bba97f8a1c1ca!2sMontville%20Art%20Gallery!5e0!3m2!1sen!2sau!4v1653882270882!5m2!1sen!2sau"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen={false}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </Card>
      <Divider />
      <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 20 }}>
        <Typography variant="h5" gutterBottom component="div" sx={{}}>
          On Display
        </Typography>
      </div>
      <Gallery
        images={galleryImages.filter(
          (i) => i.availableAt === MontvilleArtGallery.availableAt,
        )}
        showCardContent={false}
      />
    </div>
  );
};

export default ArtGalleriesPage;
