import React from 'react';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import InstaIcon from '@mui/icons-material/Instagram';
import CopyrightIcon from '@mui/icons-material/CopyrightRounded';
import { styled as muistyled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import ExternalLink from './ExternalLink';

const Item = muistyled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'right',
  color: theme.palette.text.secondary,
  justifyContent: 'center',
  alignItems: 'center',
}));

const FooterContent = styled.div`
  min-height: 100px;
  margin: 20px;
`;

const Footer: React.FC<unknown> = () => {
  return (
    <FooterContent>
      <Grid container spacing={3}>
        <Grid item xs="auto">
          <Item>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <CopyrightIcon fontSize="small" />
              <span>&nbsp;Sophie Malkin Artist 2024</span>
            </div>
          </Item>
        </Grid>
        <Grid item xs>
          <Item>
            <ExternalLink to="https://www.instagram.com/sophie.malkin.artist/">
              <InstaIcon fontSize="large" htmlColor="#E4405F" />
            </ExternalLink>
          </Item>
        </Grid>
      </Grid>
    </FooterContent>
  );
};

export default Footer;
