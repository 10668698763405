import React from 'react';
import Contact from './Contact';
import PaintingsPage from './Paintings';
import Home from './Home';
import Profile from './Profile';
import ArtGalleriesPage from './ArtGalleries';
import MyGalleryPage from './MyGallery';

export type Page = {
  name: string;
  path: string;
  component: () => JSX.Element;
};

const pages: { [key: string]: Page } = {
  Home: { name: 'Home', path: '/', component: () => <Home /> },
  Paintings: {
    name: 'Paintings',
    path: '/paintings',
    component: () => <PaintingsPage />,
  },
  // BrisbaneGallery: {
  //   name: 'Art Gallery & Studio',
  //   path: '/sophiemalkinartgallery',
  //   component: () => <MyGalleryPage />,
  // },
  ArtGalleries: {
    name: 'Montville Art Gallery',
    path: '/galleries',
    component: () => <ArtGalleriesPage />,
  },
  About: { name: 'About Me', path: '/about', component: () => <Profile /> },
  Contact: { name: 'Contact', path: '/contact', component: () => <Contact /> },
};

export default pages;
