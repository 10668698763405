import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import { useAnalytics } from 'use-analytics';
import ExternalLink from '../Components/ExternalLink';
import InternalLink from '../Components/InternalLink';

import pages from './pages';

const Profile: React.FC<unknown> = () => {
  const { page } = useAnalytics();

  React.useEffect(() => {
    // eslint-disable-next-line no-console
    document.title = 'Sophie Malkin Artist';

    page({ title: 'Home', path: pages.Home.path });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ marginTop: 60 }}>
      <Grid
        container
        justifyContent="center"
        columns={{ xs: 3, sm: 8, md: 12 }}
      >
        <Grid item xs={2} sm={4} md={4}>
          <img
            src="images/picture2.jpg"
            style={{
              display: 'block',
              width: '100%',
              marginLeft: 'auto',
              marginRight: 'auto',
              borderRadius: '10%',
            }}
            alt="Sophie Malkin"
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          margin: '0 auto',
          width: '70%',
          paddingTop: '5rem',
          paddingBottom: '5rem',
        }}
      >
        <Typography>
          Raised by art-loving parents, and watching my father (the artist{' '}
          <ExternalLink to="https://www.montvilleartgallery.com.au/artist/wayne-malkin/">
            Wayne Malkin
          </ExternalLink>
          ) paint throughout my formative years, I always had an appreciation
          for the visual arts. I started taking my art seriously in 2020,
          studying and practising in every spare moment around my town planning
          career. I finally left my day job in 2021 to focus on art full-time,
          and have not looked back since!
        </Typography>
        <br />
        <Typography>
          Oil paint is my chosen medium. I love the boldness of the paint, the
          vividness of the colour, and the way the paint can be manipulated on
          the canvas to achieve any technique. My preferred style is
          impressionism-realism, and I often seek to create a mood or atmosphere
          in my work through the use of colour.
        </Typography>
        <br />
        <br />
        <Typography variant="h6" gutterBottom>
          Awards & Features
        </Typography>
        <List>
          <Typography variant="h6">2024</Typography>
          <ListItem>
            <ListItemAvatar>
              <img
                width={100}
                style={{ marginLeft: 10, marginRight: 10 }}
                alt="The Tower Art Show Logo"
                src="images/aboutmeimages/tower_art_show.png"
              />
            </ListItemAvatar>
            <ExternalLink to="https://www.marash.qld.edu.au/news-events/events/tower-art-show/">
              <ListItemText
                primary="The Tower Art Show"
                secondary="Exhibition - Fire Show, Byron Bay & First Light, New Zealand"
              />
            </ExternalLink>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <img
                width={100}
                style={{ marginLeft: 10, marginRight: 10 }}
                alt="The Doyles Award Logo"
                src="https://thedoylesaward.com.au/wp-content/uploads/The-Doyles-logo.png"
              />
            </ListItemAvatar>
            <ExternalLink to="https://thedoylesaward.com.au">
              <ListItemText
                primary="The Doyles Art Award 2024"
                secondary="Finalist - Rocky Shallows and Cliff Face, Springbrook"
              />
            </ExternalLink>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <img
                width={100}
                style={{
                  marginRight: 10,
                  padding: 10,
                }}
                alt="Brisbane Rotary Art Show"
                src="https://rotaryart.au/1503/images/RAS_Logo_Final.jpg"
              />
            </ListItemAvatar>
            <ExternalLink to="https://rotaryart.au/">
              <ListItemText
                primary="Brisbane Rotary Art Show"
                secondary="Finalist - Point Cartwright"
              />
            </ExternalLink>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <img
                width={100}
                style={{
                  marginRight: 10,
                  padding: 10,
                }}
                alt="Royal Queensland Art Society: Brisbane Landmarks, Landscapes and Personalities 2024"
                src="https://rqas.com.au/wp-content/uploads/2018/02/RQAS-logo.png"
              />
            </ListItemAvatar>
            <ExternalLink to="https://rqas.com.au/event/brisbane-landmarks-landscapes-and-personalities-2024/">
              <ListItemText
                primary="Royal Queensland Art Society: Brisbane Landmarks, Landscapes and Personalities 2024"
                secondary="Highly Commended - Ocean's Edge"
              />
            </ExternalLink>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <img
                width={100}
                style={{
                  marginRight: 10,
                  backgroundColor: 'black',
                  padding: 10,
                }}
                alt="31 West Chambers Logo"
                src="https://images.squarespace-cdn.com/content/v1/56495098e4b021b7ebf72c37/1466305842709-19IJSZ7ZRFHNINXPGWMM/31W_logo_mono.png"
              />
            </ListItemAvatar>
            <ExternalLink to="https://www.31west.com.au/31-west-chambers-art-competition">
              <ListItemText
                primary="31 West Chambers Art Prize"
                secondary="Finalist - Bird's View over Enoggera Reservoir"
              />
            </ExternalLink>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <img
                width={100}
                style={{ marginRight: 10 }}
                alt="Dutch Artbox Logo"
                src="https://d31ezp3r8jwmks.cloudfront.net/emtfqlupm12jm3rsxqqk8tycxzc1"
              />
            </ListItemAvatar>
            <ExternalLink to="https://www.dutchartbox.com/featured-artist">
              <ListItemText
                primary="Dutch Art Box"
                secondary="Featured Artist"
              />
            </ExternalLink>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <img
                width={100}
                style={{ marginRight: 10 }}
                alt="Montville Art Gallery Logo"
                src="https://www.montvilleartgallery.com.au/wp-content/uploads/2017/07/MontvilleArtGalleryLogo-e1499494624895.jpg"
              />
            </ListItemAvatar>
            <ExternalLink to="https://www.instagram.com/p/C1l5Jl4NL4Y/">
              <ListItemText
                primary="Montville Art Gallery"
                secondary="January Featured Artist of the Month"
              />
            </ExternalLink>
          </ListItem>
          <Typography variant="h6">2023</Typography>
          <ListItem>
            <ListItemAvatar>
              <Avatar
                style={{ marginLeft: 10, marginRight: 10 }}
                variant="square"
                alt="The Doyles Award Logo"
                src="https://thedoylesaward.com.au/wp-content/uploads/D-2023.png"
              />
            </ListItemAvatar>
            <ExternalLink to="https://thedoylesaward.com.au/winners/adult-2023/">
              <ListItemText
                primary="The Doyles Art Award 2023"
                secondary="Highly Commended - Resting (Obi Obi Creek)"
              />
            </ExternalLink>
          </ListItem>
        </List>
      </Box>
    </div>
  );
};

export default Profile;
