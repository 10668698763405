import React from 'react';
import InstaIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';

type Contact = {
  type: string;
  link: string;
  description: string;
  icon: JSX.Element;
  onClick?: () => void;
};

const contactList: Contact[] = [
  {
    type: 'Instagram',
    icon: <InstaIcon fontSize="medium" htmlColor="#E4405F" />,
    link: 'https://www.instagram.com/sophie.malkin.artist/',
    description: '',
  },
  {
    type: 'Facebook',
    icon: <FacebookIcon fontSize="medium" htmlColor="#4267B2" />,
    link: 'https://www.facebook.com/sophie.malkin.artist/',
    description: '',
  },
  {
    type: 'Email',
    icon: <EmailIcon fontSize="medium" htmlColor="#131418" />,
    link: 'https://mailhide.io/e/9gk174Jv',
    onClick: () => {
      window.open(
        'https://mailhide.io/e/9gk174Jv',
        'mailhidepopup',
        'width=580,height=635',
      );
    },
    description:
      'Please contact me with any enquiries about available paintings, commissions, general questions about my work, or if you just want to get in touch!',
  },
];

export default contactList;
