import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import GalleryDialog from './GalleryDialog';
import styled from 'styled-components';
import PaintingDetails from './PaintingDetails';
import { useAnalytics } from 'use-analytics';

export type ImageDetails = {
  img: string;
  title: string;
  description?: string;
  type?: string;
  width?: number;
  height?: number;
  frameWidth?: number;
  frameHeight?: number;
  price?: number;
  availableAt?: string;
  availableAtUrl?: string;
};

export interface GalleryProps {
  images: ImageDetails[];
  showDetails?: boolean;
  elevation?: number;
  showTitles?: boolean;
  showDescription?: boolean;
  showCardContent?: boolean;

  minimumColumns?: number;
}
const ImageContainer = styled.div`
  background-color: white;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const Gallery: React.FC<GalleryProps> = ({
  images,
  showDetails = true,
  showTitles = true,
  showDescription = true,
  showCardContent = true,
  elevation = 0,
  minimumColumns = 3,
}) => {
  const { track } = useAnalytics();

  const [selectedImage, selectImage] = React.useState<ImageDetails>(images[0]);

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const handleClickOpen = (image: ImageDetails) => {
    track('image_view', { image_name: image.title });
    selectImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  let columns = minimumColumns;

  if (!isMd) columns = 2;
  if (!isSm) columns = 1;

  return (
    <>
      <Masonry columns={columns} spacing={0}>
        {images.map((image) => (
          <div key={`${image.title}-image`}>
            <div
              key={image.img}
              style={{
                margin: 20,
              }}
            >
              <Card elevation={elevation}>
                <ImageContainer>
                  <CardMedia
                    component="img"
                    onClick={() => handleClickOpen(image)}
                    image={`${image.img}`}
                    alt={`${image.title}`}
                  />
                </ImageContainer>
                {showCardContent ? (
                  <CardContent>
                    {showTitles ? (
                      <Typography
                        gutterBottom
                        sx={{
                          fontSize: 18,
                          verticalAlign: 'middle',
                          display: 'inline-flex',
                          fontWeight: 500,
                        }}
                      >
                        {image.title}
                      </Typography>
                    ) : (
                      ''
                    )}

                    <PaintingDetails
                      image={image}
                      showDescription={showDescription}
                      showDetails={showDetails}
                    />
                  </CardContent>
                ) : (
                  ''
                )}
                {showDetails ? (
                  <div
                    style={{
                      marginTop: 5,
                    }}
                  >
                    <Typography variant="h6">{image.title}</Typography>
                    <PaintingDetails
                      image={image}
                      showDescription={showDescription}
                      showDetails={showDetails}
                    />
                  </div>
                ) : (
                  ''
                )}
              </Card>
            </div>
          </div>
        ))}
      </Masonry>

      <GalleryDialog
        open={open}
        handleClose={handleClose}
        content={
          <>
            <img
              src={`${selectedImage?.img}`}
              width="100%"
              alt={selectedImage.title}
              loading="lazy"
            />
            <Typography variant="h6"> {selectedImage.title}</Typography>
            <PaintingDetails
              image={selectedImage}
              showDescription={showDescription}
              showDetails={showDetails}
            />
          </>
        }
      />
    </>
  );
};

export default Gallery;
