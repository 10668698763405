import React from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Route, Routes } from 'react-router-dom';
import pages from './Pages/pages';
import { AppBar, Divider, Typography } from '@mui/material';
import TopBar from './Components/TopBar';
import './App.css';
import ScrollToTop from './Components/ScrollToTop';
import Footer from './Components/Footer';

const App: React.FC<unknown> = () => {
  return (
    <>
      <CssBaseline />
      <Container maxWidth="xl">
        <div className="App">
          <TopBar pages={Object.values(pages)} />
          <Divider />
          <ScrollToTop>
            <Routes>
              {Object.values(pages).map((page) => (
                <Route
                  key={`page-route-${page.name}`}
                  path={page.path}
                  element={page.component()}
                />
              ))}
            </Routes>
          </ScrollToTop>
          <Divider variant="middle" style={{ marginBottom: 20 }} />
          <Footer />
        </div>
      </Container>
    </>
  );
};

export default App;
