import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface InternalLinkProps {
  to: string;
  color?: string;
  onClick?: () => void;
}

const InternalLinkContainer = styled.span`
  color: ${(props) => (props.color ? props.color : '#3a82ff')};
  &:hover {
    cursor: pointer;
  }
`;

const InternalLink: React.FC<InternalLinkProps> = ({
  to,
  onClick = () => null,
  children,
  color = '#3a82ff',
}) => {
  const navigate = useNavigate();

  return (
    <InternalLinkContainer
      onClick={() => {
        navigate(to);
        if (onClick) {
          onClick();
        }
      }}
      color={color}
    >
      {children}
    </InternalLinkContainer>
  );
};

export default InternalLink;
