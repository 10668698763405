import { Box, Typography } from '@mui/material';
import React from 'react';
import { useAnalytics } from 'use-analytics';
import Gallery from '../Components/Gallery';
import InternalLink from '../Components/InternalLink';
import images from '../data/galleryImages';

import pages from './pages';

const Home: React.FC<unknown> = () => {
  const { page } = useAnalytics();

  React.useEffect(() => {
    // eslint-disable-next-line no-console
    document.title = 'Sophie Malkin Artist';

    page({ title: 'Home', path: pages.Home.path });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Gallery
        images={images.slice(0, 6)}
        showCardContent={false}
        minimumColumns={3}
      />
      <Box
        sx={{
          margin: '0 auto',
          width: '70%',
          marginTop: '60px',
          marginBottom: '60px',
        }}
      >
        <Typography textAlign="center" variant="h6">
          <InternalLink to="/paintings">See more</InternalLink>
        </Typography>
      </Box>
    </>
  );
};

export default Home;
