import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ExternalLink from '../Components/ExternalLink';
import Divider from '@mui/material/Divider';
import CardMedia from '@mui/material/CardMedia';
import contactList from '../data/contactList';
import { useAnalytics } from 'use-analytics';
import pages from './pages';

const Contact: React.FC<unknown> = () => {
  const { page } = useAnalytics();

  React.useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('Contact Page');
    document.title = 'Sophie Malkin Artist: Contact';

    page({ title: 'Contact', path: pages.Contact.path });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div style={{ marginTop: 30 }}>
      <Box
        sx={{
          margin: '0 auto',
          width: '70%',
          paddingTop: '5rem',
          marginBottom: '60px',
        }}
      >
        <Typography textAlign="center">
          Contact me by{' '}
          <ExternalLink
            to={contactList[2].link}
            onClick={contactList[2].onClick}
          >
            email
          </ExternalLink>{' '}
          <br />
          or <br />
          Follow my journey on social media links below, where I regularly post
          new paintings, progress pictures and updates!
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1, marginBottom: 60 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 4 }}
          columns={{ xs: 2, sm: 8, md: 12 }}
        >
          {contactList.map((contact) => (
            <Grid item xs={12} sm={12} md={6} key={`index${contact.type}`}>
              <Card sx={{ minWidth: 275 }}>
                <ExternalLink
                  to={contact.link}
                  onClick={contact.onClick}
                  color="black"
                >
                  <CardContent>
                    <Typography
                      sx={{
                        fontSize: 18,
                        verticalAlign: 'middle',
                        display: 'inline-flex',
                      }}
                    >
                      {contact.icon}&nbsp;&nbsp;
                      {contact.type}
                    </Typography>
                    <Typography variant="caption" gutterBottom component="div">
                      {contact.description}
                    </Typography>
                  </CardContent>
                </ExternalLink>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default Contact;
