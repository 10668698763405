import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from 'styled-components';
import { AnalyticsProvider } from 'use-analytics';
import analytics from './Utils/analytics';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3a82ff',
    },
    secondary: {
      main: '#075fd3',
    },
  },
  // custom theme variables
  bg: {
    main: '#fff',
    light: '#F4F5F7',
  },
  text: {
    main: '#172B4D',
    light: '#262930',
  },
  typography: {
    fontFamily: [
      'Courgette',
      'Marck Script',
      'Pacifico',
      'Tenor Sans',
      'Arial',
      'sans-serif',
    ].join(','),
  },
});

ReactDOM.render(
  <AnalyticsProvider instance={analytics}>
    <BrowserRouter>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </React.StrictMode>
    </BrowserRouter>
  </AnalyticsProvider>,

  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
