import React from 'react';
import Gallery from '../Components/Gallery';
import images from '../data/galleryImages';
import { useAnalytics } from 'use-analytics';
import pages from './pages';

const PaintingsPage: React.FC<unknown> = () => {
  const { page } = useAnalytics();

  React.useEffect(() => {
    // eslint-disable-next-line no-console
    document.title = 'Sophie Malkin Artist: Paintings';
    page({ title: 'Gallery', path: pages.Paintings.path });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Gallery images={images} showCardContent={false} />;
};

export default PaintingsPage;
