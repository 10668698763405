import React from 'react';
import styled from 'styled-components';
import { useAnalytics } from 'use-analytics';

interface ExternalLinkProps {
  to: string;
  color?: string;
  onClick?: () => void;
}

const Anchor = styled.a`
  text-decoration: none;
  color: ${(props) => (props.color ? props.color : '#3a82ff')};
`;

const ExternalLink: React.FC<ExternalLinkProps> = ({
  to,
  onClick,
  children,
  color,
}) => {
  const { track } = useAnalytics();

  return (
    <Anchor
      href={to}
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        track('external_link_click', { to });
        if (onClick) onClick();
      }}
      color={color}
    >
      {children}
    </Anchor>
  );
};

ExternalLink.defaultProps = {
  onClick: () => null,
  color: '#3a82ff',
};

export default ExternalLink;
