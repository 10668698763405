import React from 'react';
import { Typography } from '@mui/material';
import ExternalLink from './ExternalLink';
import { ImageDetails } from './Gallery';
import InternalLink from './InternalLink';
import { FromHillsdon, FromMe, Sold } from '../data/galleryImages';

export interface PaintingDetailsProps {
  image: ImageDetails;
  showDetails?: boolean;
  showDescription?: boolean;
}

const PaintingDetails: React.FC<PaintingDetailsProps> = ({
  image,
  showDetails = true,
  showDescription = true,
}) => {
  let availbilityDetails: JSX.Element = (
    <Typography variant="subtitle2" color="text.secondary">
      Not Available
    </Typography>
  );

  if (image.availableAt) {
    const label = 'Available: ';
    if (image.availableAtUrl) {
      if (
        image.availableAtUrl === FromMe.availableAtUrl ||
        image.availableAtUrl === FromHillsdon.availableAtUrl
      ) {
        availbilityDetails = (
          <Typography variant="subtitle2" color="text.secondary">
            {label}
            <InternalLink to={image.availableAtUrl}>
              {image.availableAt}
            </InternalLink>
          </Typography>
        );
      } else {
        availbilityDetails = (
          <Typography variant="subtitle2" color="text.secondary">
            {label}

            <ExternalLink to={image.availableAtUrl}>
              {image.availableAt}
            </ExternalLink>
          </Typography>
        );
      }
    } else {
      availbilityDetails = (
        <Typography variant="subtitle2" color="text.secondary">
          {image.availableAt}
        </Typography>
      );
    }
  }

  if (image.availableAt === 'sold') {
    availbilityDetails = (
      <Typography variant="subtitle2" color="red">
        SOLD
      </Typography>
    );
  }

  if (image.availableAt === 'commision') {
    availbilityDetails = (
      <Typography variant="subtitle2" color="text.primary">
        Commission
      </Typography>
    );
  }

  let size: JSX.Element | string = '';

  if (image.width || image.height) {
    size = (
      <Typography variant="subtitle2" color="text.secondary">
        Painting size: {image.height}cm x {image.width}cm
      </Typography>
    );
  }

  let frameSize: JSX.Element | string = '';

  if (image.frameWidth || image.frameHeight) {
    frameSize = (
      <Typography variant="subtitle2" color="text.secondary">
        Frame size: {image.frameHeight}cm x {image.frameWidth}cm
      </Typography>
    );
  }

  let price: JSX.Element | string = '';

  if (image.price) {
    if (image.availableAt === Sold.availableAt) {
      price = '';
    } else {
      price = (
        <Typography variant="subtitle2" color="text.secondary">
          ${image.price}
        </Typography>
      );
    }
  }

  let details: JSX.Element | string = '';

  if (showDetails) {
    const { type } = image;
    const hasFramed = type?.includes('framed');
    const imageType = frameSize !== '' && !hasFramed ? `${type}, framed` : type;

    details = (
      <>
        <Typography variant="subtitle2" color="text.secondary">
          {imageType}
        </Typography>
        {size}
        {frameSize}
        {availbilityDetails}
        {price}
      </>
    );
  }

  let description: JSX.Element | string = '';

  if (showDescription) {
    description = (
      <Typography variant="body2" color="text.secondary">
        {image.description}
      </Typography>
    );
  }

  return (
    <div>
      {details} {description}
    </div>
  );
};

export default PaintingDetails;
