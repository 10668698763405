import Analytics from 'analytics';
import amplitudePlugin from '@analytics/amplitude';

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const apikey = isDev
  ? '87621cab3873a8c2b621fe4d82323109' // https://analytics.amplitude.com/ankith/settings/projects/392016/general
  : 'd6d279416b4252c2f1dc561db471c4f7'; // https://analytics.amplitude.com/ankith/settings/projects/392514/general

export default Analytics({
  app: 'sophiemalkinartist',
  plugins: [
    amplitudePlugin({
      apiKey: apikey,
    }),
  ],
});
