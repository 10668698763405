import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { useLocation, Link } from 'react-router-dom';
import { ButtonProps, styled, useMediaQuery, useTheme } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import type { Page } from '../Pages/pages';

interface TopBarProps {
  pages: Page[];
}

interface NavButton extends ButtonProps {
  active: string;
}

interface NavMenuItemTypography extends TypographyProps {
  active: string;
}

const NavButton = styled(Link)<NavButton>(({ active }) => ({
  color: active === 'true' ? '#3a82ff !important' : 'black',
  textDecoration: 'none' /* no underline */,
  paddingLeft: 30,
  fontSize: '1.1rem',
}));

const NavMenuItemTypography = styled(Link)<NavMenuItemTypography>(
  ({ active }) => ({
    color: active === 'true' ? '#3a82ff  !important' : 'black',
    textDecoration: 'none' /* no underline */,
    padding: 10,
    fontSize: '1.1rem',
  }),
);

const TopBar = ({ pages }: TopBarProps) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const location = useLocation();

  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.up('sm'));
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  let variant: Variant = 'h2';

  if (!isMd) variant = 'h3';
  if (!isSm) variant = 'h3';
  if (!isXs) variant = 'h5';

  return (
    <Container maxWidth="xl" style={{ marginBottom: 10 }}>
      <Toolbar disableGutters>
        <Typography
          variant={variant}
          noWrap
          component="a"
          href="/"
          sx={{
            pr: 1,
            display: {
              flexGrow: 1,
              xs: isXs ? 'flex' : 'flex',
              sm: isSm ? 'flex' : 'none',
              md: isMd ? 'flex' : 'none',
            },
            fontWeight: 350,
            letterSpacing: '.3rem',
            color: 'black',
            textDecoration: 'none',
          }}
          style={{ fontFamily: 'Tenor Sans' }}
        >
          SOPHIE MALKIN
        </Typography>

        <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {pages.map((page) => (
              <MenuItem
                key={`navigation-link-${page.name}`}
                onClick={handleCloseNavMenu}
              >
                <NavMenuItemTypography
                  to={page.path}
                  active={page.path === location.pathname ? 'true' : 'false'}
                >
                  {page.name}
                </NavMenuItemTypography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box
          sx={{
            flexGrow: 0,
            display: { xs: 'none', md: 'flex' },
          }}
        >
          {pages.map((page) => (
            <NavButton
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: 'inherit', display: 'block' }}
              active={page.path === location.pathname ? 'true' : 'false'}
              key={`navigation-link-${page.name}`}
              to={page.path}
              style={{ fontFamily: 'Tenor Sans' }}
            >
              {page.name}
            </NavButton>
          ))}
        </Box>
      </Toolbar>
    </Container>
  );
};
export default TopBar;
