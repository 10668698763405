import React from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@mui/material';

export interface DiaglogProps {
  open: boolean;
  title?: string;
  content: JSX.Element;
  handleClose: () => void;
}

const GalleryDialog = ({ open, title, content, handleClose }: DiaglogProps) => {
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {title ? (
          <DialogTitle id="responsive-dialog-title">
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}> {title}</Box>
            </Box>
          </DialogTitle>
        ) : (
          ''
        )}
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GalleryDialog;
